import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';

const AllProjects = ({ currentBatch, setCurrentProject }) => {
  const projects = currentBatch.projects || [];
  return (
    <TableContainer component={Card}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell align='center'>Tasks</TableCell>
            <TableCell align='center'>User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project, index) => (
            <TableRow
              hover
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
              }}
              onClick={() => setCurrentProject(project)}
            >
              <TableCell component='th' scope='row'>
                {project.project?.title}
              </TableCell>
              <TableCell align='center'>
                {project.project?.tasks?.length}
              </TableCell>

              <TableCell align='center'>
                {project.projectUser?.length}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllProjects;
