import axios from 'axios';
import {
  FETCH_COACH_REQUEST,
  FETCH_COACH_SUCCESS,
  FETCH_COACH_FAILURE,
} from './coachTypes';
import { API_URL } from '../../links/API_LINKS';

export const fetchCoachDetails = (email) => {
  return (dispatch) => {
    dispatch(fetchCoachRequest());
    axios
      .get(API_URL + '/coach', {
        params: { email: email },
      })
      .then((response) => {
        const coach = response.data;
        dispatch(fetchCoachSuccess(coach));
      })
      .catch((error) => {
        dispatch(fetchCoachFailure(error.message));
      });
  };
};

export const fetchCoachRequest = () => {
  return {
    type: FETCH_COACH_REQUEST,
  };
};

export const fetchCoachSuccess = (coach) => {
  return {
    type: FETCH_COACH_SUCCESS,
    payload: coach,
  };
};

export const fetchCoachFailure = (error) => {
  return {
    type: FETCH_COACH_FAILURE,
    payload: error,
  };
};
