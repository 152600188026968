import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { IconButton, Box, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from 'react-query';

import Edit from './Edit';
import { API_URL_V2 } from '../../../../../links/API_LINKS';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AnnouncementBody = ({ row, currentBatch }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const Authorization = localStorage.getItem('authorization');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteOpen = () => setDeleteModalOpen(true);
  const handleDeleteClose = () => setDeleteModalOpen(false);
  const queryClient = useQueryClient();

  function deleteAnnouncement(announcementId) {
    axios({
      method: 'DELETE',
      url: API_URL_V2 + '/vga-announcement',
      data: { announcementId },
      headers: { Authorization },
    }).then(function (response) {
      queryClient.invalidateQueries(`fetchAnnouncement${currentBatch._id}}`);
      handleDeleteClose();
    });
  }
  return (
    <>
      <TableRow
        hover
        // key={index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          cursor: 'pointer',
        }}
        // onClick={() => setCurrentEvent(event)}
      >
        <TableCell component='th' scope='row'>
          {row.title}
        </TableCell>
        <TableCell align='center'>
          {format(new Date(row.createdAt), 'do LLLL yyyy p')}
        </TableCell>

        <TableCell align='center'>{row.viewUsers.length}</TableCell>
        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={() => handleOpen()}
          >
            <EditIcon color='primary' />
          </IconButton>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={() => handleDeleteOpen()}
          >
            <DeleteIcon color='error' />
          </IconButton>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Edit
            announcement={row}
            currentBatch={currentBatch}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Dialog
        open={deleteModalOpen}
        onClose={handleDeleteClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure you want to delete this announcement?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {row.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button
            onClick={() => {
              deleteAnnouncement(row._id);
            }}
            autoFocus
            color='warning'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnnouncementBody;
