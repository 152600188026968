import React, { useEffect } from 'react';
import { Container, Typography, CircularProgress, Box } from '@mui/material';

import BatchSummaryTable from './BatchSummaryTable';

import { connect } from 'react-redux';
import { fetchCoachBatchDetails } from '../../../redux';

const AllBatches = ({
  setCurrentBatch,
  fetchCoachBatchDetails,
  coachBatch,
}) => {
  useEffect(() => {
    fetchCoachBatchDetails();
  }, [fetchCoachBatchDetails]);

  return (
    <div>
      <Container maxWidth='xl'>
        {coachBatch.loading && !coachBatch.coachBatch && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight='50vh'
          >
            <CircularProgress />
          </Box>
        )}
        {coachBatch.error && <Typography>Error fetching data</Typography>}

        {coachBatch.coachBatch && (
          <BatchSummaryTable
            setCurrentBatch={setCurrentBatch}
            allBatch={coachBatch.coachBatch.data}
          />
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coachBatch: state.coachBatch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCoachBatchDetails: () => dispatch(fetchCoachBatchDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllBatches);
