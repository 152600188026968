import React, { useState } from 'react';
import { Box } from '@mui/material';
import AllProjects from './AllProjects';
import ProjectDetails from './ProjectDetails';

const Index = ({ currentBatch }) => {
  const [currentProject, setCurrentProject] = useState(null);
  return (
    <Box>
      {currentProject ? (
        <ProjectDetails
          setCurrentProject={setCurrentProject}
          currentProject={currentProject}
          currentBatch={currentBatch}
        />
      ) : (
        <AllProjects
          setCurrentProject={setCurrentProject}
          currentBatch={currentBatch}
        />
      )}
    </Box>
  );
};

export default Index;
