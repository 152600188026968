// import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
// import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  // MenuItem,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';
// components
import MenuPopover from '../../misc/MenuPopover';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { signOut } from '../../../redux';
import { useNavigate } from 'react-router-dom';
// import Modal from '@mui/material/Modal';
// import Profile from './Profile';

// ----------------------------------------------------------------------

const AccountPopover = ({ coach, signOut }) => {
  const navigate = useNavigate();
  const myCoach =
    coach.coach && coach.coach.length === 1 ? coach.coach[0] : null;

  async function awsSignOut() {
    try {
      await Auth.signOut();
      signOut();
      localStorage.removeItem('authorization');
      navigate('/', { replace: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src={myCoach && myCoach.coachImageUrl} alt='photoURL' />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {myCoach && myCoach.name}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {myCoach && myCoach.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* <MenuItem
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={homeFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Home
        </MenuItem>
        <MenuItem
          //  onClick={() => setOpenModal(true)}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={personFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Profile
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Settings
        </MenuItem> */}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={() => awsSignOut()}
            fullWidth
            color='inherit'
            variant='outlined'
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    coach: state.coach,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPopover);
