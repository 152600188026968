import { CircularProgress, Box, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { API_URL_V2 } from '../../../../../links/API_LINKS';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import AnnouncementBody from './AnnouncementBody';


const All = ({ currentBatch }) => {
  const batchId = currentBatch._id;
  const Authorization = localStorage.getItem('authorization');
  const fetchAnnouncement = async () => {
    const res = await axios.get(API_URL_V2 + '/vga-announcement', {
      params: { batchId },
      headers: { Authorization },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchAnnouncement${batchId}}`,
    fetchAnnouncement,
    { select: (data) => data?.data?.data }
  );

  return (
    <Box>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Box>
          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Announcement Title</TableCell>
                  <TableCell align='center'>Added on</TableCell>
                  <TableCell align='center'>Total Views</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((el, index) => (
                  <AnnouncementBody
                    row={el}
                    key={index}
                    currentBatch={currentBatch}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default All;
