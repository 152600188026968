import React from 'react';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL_V2 } from '../../../links/API_LINKS';

import AppCurrentVisits from '../layouts/AppCurrentVisits';

import DataCard from '../layouts/DataCard';
import TicketChart from './TicketChart';
import PeopleIcon from '@mui/icons-material/People';

const infoLighter = '#D0F2FF';
const infoDarker = '#04297A';

const TicketInfo = ({ coachId }) => {
  const Authorization = localStorage.getItem('authorization');
  const fetchAllCoaches = async () => {
    const res = await axios.get(API_URL_V2 + '/ticket/overview', {
      headers: { Authorization },
      params: {
        assignee: coachId,
      },
    });
    return res;
  };
  const { status, data } = useQuery('allCoachesPanel', fetchAllCoaches);

  const ticketLabel = ['Closed', 'Open'];
  let ticketData = [];
  if (status === 'success') {
    ticketData = [data.data.closedTicket, data.data.openTicket];
  }

  return (
    <Container maxWidth='xl'>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}

      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppCurrentVisits
                heading='Ticket Overview'
                label={ticketLabel}
                data={ticketData}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <DataCard
                total={data.data.avgFRT}
                title='Avg FRT'
                icon={PeopleIcon}
                bgColor={infoLighter}
                textColor={infoDarker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataCard
                total={data.data.avgTAT}
                title='Avg TAT'
                icon={PeopleIcon}
                bgColor={infoLighter}
                textColor={infoDarker}
              />
            </Grid>
          </Grid>
          <TicketChart data={data.data.modifiedChartData} />
        </Container>
      )}
    </Container>
  );
};

export default TicketInfo;
