import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Stack, Typography } from '@mui/material';

function BatchUser({ users, setUserEmail }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let rows = [];
  rows = users.map((user) => {
    return {
      name: user.name,
      email: user.email,
      mentor: user.mentor ? user.mentor.name : '',
      coach: user.coach ? user.coach.name : '',
      company: user.company ? user.company.name : '',
      status: user.status,
    };
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
        aria-label='table'
        size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>Name</TableCell>
            <TableCell align='left'>Email</TableCell>
            <TableCell align='left'>Status</TableCell>
            <TableCell align='left'>Mentor</TableCell>
            <TableCell align='left'>Coach</TableCell>
            <TableCell align='left'>Company</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow
                  onClick={() => setUserEmail(row.email)}
                  hover
                  key={row.email}
                  sx={{ cursor: 'pointer' }}>
                  <TableCell component='th' scope='row' padding='none'>
                    <Stack direction='row' alignItems='left' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {row.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='left'>{row.email}</TableCell>
                  <TableCell align='left'>{row.status}</TableCell>
                  <TableCell align='left'>{row.mentor}</TableCell>
                  <TableCell align='left'>{row.coach}</TableCell>
                  <TableCell align='left'>{row.company}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default BatchUser;
