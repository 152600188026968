import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import { Box, CircularProgress } from '@mui/material';
import AddAttendance from './AddAttendance';

const UpdateAttendance = ({
  users,
  currentEvent,
  setCurrentEvent,
  currentBatch,
}) => {
  const [loading, setLoading] = useState(true);
  const [audienceDetail, setAudienceDetail] = useState({});

  useEffect(() => {
    (async () => {
      const result = await axios(
        API_URL_V2 + '/batch/events-audience-criteria',
        {
          params: {
            eventId: currentEvent._id,
            batchId: currentBatch._id,
          },
        }
      );
      if (result.data && result.data.data) {
        setAudienceDetail(result.data.data);
        setLoading(false);
      }
    })();
  }, [setLoading, currentEvent, currentBatch]);
  return (
    <>
      {loading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      ) : (
        <AddAttendance
          users={users}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          audienceDetail={audienceDetail}
          currentBatch={currentBatch}
        />
      )}
    </>
  );
};

export default UpdateAttendance;
