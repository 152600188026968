import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Overview from './Overview';
import { connect } from 'react-redux';
import { fetchCoachDashboardDetails } from '../../../redux';

const Index = ({ fetchCoachDashboardDetails, coachDashboard }) => {
  useEffect(() => {
    fetchCoachDashboardDetails();
  }, [fetchCoachDashboardDetails]);

  return (
    <div>
      {coachDashboard.loading && !coachDashboard.coachDashboard && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}

      {coachDashboard.error && <div>Error fetching data</div>}
      {coachDashboard.coachDashboard && (
        <Overview data={coachDashboard.coachDashboard.data} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coach: state.coach,
    coachDashboard: state.coachDashboard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCoachDashboardDetails: () => dispatch(fetchCoachDashboardDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
