import React, { useState } from 'react';
import Questions from './Questions';
// import { Box } from "@mui/material";
// import Progress from "./Progress";

const database = [
  {
    category: 'Performance Assessment',
    question: 'The overall performance of the batch of learners.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'Performance Assessment',
    question: `How much improvement did you observe in the learners' skill set throughout this apprenticeship?`,
    questionType: 'radio',
    value: '',
  },
  {
    category: 'Performance Assessment',
    question: 'Effectiveness of learners in applying knowledge to projects.',
    questionType: 'radio',
    value: '',
  },
  {
    category: 'Understanding of Domain Concepts',
    question: `Learners' understanding of domain concepts.`,
    questionType: 'radio',
    value: '',
  },
  {
    category: 'Participation and Engagement',
    question: `The level of learners' participation during the sessions.`,
    questionType: 'radio',
    value: '',
  },
  {
    category: 'Input',
    question: `Any challenges faced by the learners:`,
    questionType: 'text',
    value: '',
  },
  {
    category: 'Input',
    question: `The feedback on the batch:`,
    questionType: 'text',
    value: '',
  },
];

const Index = ({ setFeedbackSubmitted, currentBatch }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);

  return (
    <Questions
      batchId={currentBatch._id}
      setFeedbackSubmitted={setFeedbackSubmitted}
      database={database}
      currentQuestion={currentQuestion}
      setCurrentQuestion={setCurrentQuestion}
    />
  );
};

export default Index;
