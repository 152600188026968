import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL_V2 } from '../../../links/API_LINKS';
import { CircularProgress, Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import LinkIcon from '@mui/icons-material/Link';

const DailySchedule = () => {
  const Authorization = localStorage.getItem('authorization');
  const fetchAllEvents = async () => {
    const res = await axios.get(API_URL_V2 + '/coach/daily-schedule', {
      headers: { Authorization },
    });
    return res;
  };

  const { status, data } = useQuery('fetchAllEvents', fetchAllEvents);
  let events = [];
  if (status === 'success') {
    events = data.data.data.map((e) => {
      return e.events;
    });
  }
  let finalEvents = events.flat(1);

  // let tasks = [];
  // if (status === 'success') {
  //   tasks = data.data.data.map((t) =>
  //     t.tasks.filter((myEvent) => {
  //       return myEvent && myEvent.status === 'publish';
  //     })
  //   );
  // }
  // let finalTasks = tasks.flat(1);
  // let taskEvents = [...finalEvents, ...finalTasks];
  let sortedEvents = finalEvents.sort(
    (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
  );

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <div>
          <Typography variant='h5'>Daily Schedule</Typography>
          {sortedEvents.length ? (
            sortedEvents.slice(0, 3).map((te, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    margin: '6px 0',
                    width: '250px',
                    backgroundColor: '#10ADB9',
                  }}
                >
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      borderBottom: '1px solid #10ADB9',

                      paddingLeft: '16px',
                      color: 'white',
                    }}
                  >
                    {index + 1}.
                  </p>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 10,
                        fontWeight: 'bold',
                        borderBottom: '1px solid #10ADB9',
                        color: 'white',
                        paddingLeft: '10px',
                      }}
                    >
                      {te.title} ({format(new Date(te.dateTime), 'do LLLL p')})
                    </p>
                    {te.link && (
                      <a
                        href={te.link}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          textDecoration: 'none',
                          color: 'white',
                          paddingLeft: '6px',
                        }}
                      >
                        <LinkIcon />
                      </a>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <Typography>No Events for Today</Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default DailySchedule;
