import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card, Button, Box, Typography } from '@mui/material';
import EditAttendance from './EditAttendance';
import EventFeedback from './EventFeedback';

const ShowAttendance = ({ eventAttendance, currentEvent, setCurrentEvent }) => {
  const [edit, setEdit] = useState(false);
  console.log('currentEvent', currentEvent);

  const feedbacks = currentEvent.feedbacks || [];
  const total = eventAttendance.length;
  const present = eventAttendance.filter((el) => el.status).length;
  return edit ? (
    <EditAttendance
      currentEvent={currentEvent}
      attendance={eventAttendance}
      setCurrentEvent={setCurrentEvent}
    />
  ) : (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', p: 1, m: 1 }}>
        <Button variant='outlined' onClick={() => setEdit(true)}>
          Edit Attendance
        </Button>
        <Typography m={2} variant='h6'>
          {present} / {total}
        </Typography>
      </Box>
      <EventFeedback feedbacks={feedbacks} />
      <TableContainer component={Card}>
        <Table
          sx={{ minWidth: 450, borderRadius: 2, shadow: 2 }}
          aria-label='table'>
          <TableHead>
            <TableRow>
              <TableCell> Name</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Attendance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventAttendance.map((user, index) => (
              <TableRow hover key={index}>
                <TableCell component='th' scope='row'>
                  {user.name}
                </TableCell>
                <TableCell align='center'>{user.email}</TableCell>

                <TableCell align='center'>
                  {user.status ? (
                    <Typography>P</Typography>
                  ) : (
                    <Typography color='#FF0000'>A</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowAttendance;
