import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';

import { format } from 'date-fns';

export default function AllEvents({ allEvent, setCurrentEvent }) {
  return (
    <TableContainer component={Card}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'>
        <TableHead>
          <TableRow>
            <TableCell>Event Name</TableCell>
            <TableCell align='center'>Time</TableCell>
            <TableCell align='center'>link</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allEvent.map((event, index) => {
            const eventDate = new Date(event.dateTime);
            const currentDate = new Date();
            const isPastEvent = eventDate < currentDate;
            return (
              <TableRow
                hover={isPastEvent}
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: isPastEvent ? 'pointer' : 'default',
                  backgroundColor: !isPastEvent ? '#f0f0f0' : 'inherit',
                }}
                onClick={isPastEvent ? () => setCurrentEvent(event) : null}>
                <TableCell component='th' scope='row'>
                  {event.title}
                </TableCell>
                <TableCell align='center'>
                  {format(eventDate, 'do LLLL yyyy p')}
                </TableCell>
                <TableCell align='center'>
                  <a href={event.link} target='_blank' rel='noreferrer'>
                    Zoom Link
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
