import React from 'react';
import { Grid, Container } from '@mui/material';
import DataCard from '../../../../layouts/DataCard';
import AppCurrentVisits from '../../../../layouts/AppCurrentVisits';

import PeopleIcon from '@mui/icons-material/People';
import TaskIcon from '@mui/icons-material/Task';
import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import DomainIcon from '@mui/icons-material/Domain';

const infoLighter = '#D0F2FF';
const infoDarker = '#04297A';
const warningLighter = '#FFF7CD';
const warningDarker = '#7A4F01';
const primaryLighter = '#C8FACD';
const primaryDarker = '#005249';

const BatchOverview = ({ currentBatch }) => {
  const batchId = currentBatch._id;
  const totalUser = currentBatch.users.length;
  const nowDate = +new Date();
  const pastEvents = currentBatch.events.filter((event) => {
    const eventDate = +new Date(event.dateTime);
    return eventDate <= nowDate;
  });
  const totalPastEvent = pastEvents.length;
  const pastTasks = currentBatch.tasks.filter((task) => {
    const taskDate = +new Date(task.dueDateTime);
    return taskDate <= nowDate;
  });
  const totalPastTask = pastTasks.length;

  const totalSubmissions = pastTasks.reduce(
    (total, task) =>
      total +
      task.taskUsers.filter((user) => {
        return user.submittedFile;
      }).length,
    0
  );

  const submissionRate = Math.round(
    (totalSubmissions * 100) / (totalPastTask * totalUser)
  );

  const pendingTask = totalPastTask * totalUser - totalSubmissions;
  const onTimeTask = pastTasks.reduce(
    (total, task) =>
      total +
      task.taskUsers.filter((sub) => {
        if (sub.submittedFile) {
          const taskDueDate = +new Date(task.dueDateTime);
          const submitDate = +new Date(sub.submissionTime);
          return submitDate <= taskDueDate;
        } else {
          return false;
        }
      }).length,
    0
  );

  const lateSubmissions = totalSubmissions - onTimeTask;
  const submissionLabel = ['On Time', 'Late', 'Pending'];
  let submissionData = [onTimeTask, lateSubmissions, pendingTask];

  const Authorization = localStorage.getItem('authorization');
  const fetchRating = async () => {
    const res = await axios.get(API_URL_V2 + '/dashboard/coach/event-rating', {
      params: { batchId },
      headers: { Authorization },
    });
    return res;
  };

  const { status, data: ratingData } = useQuery(
    `fetchRating${batchId}`,
    fetchRating
  );

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total={totalUser}
            title='No of Learners'
            icon={PeopleIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total={totalPastEvent}
            title='Sessions Lead'
            icon={TaskIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
        {status === 'success' && ratingData.data.data && (
          <Grid item xs={12} sm={6} md={4}>
            <DataCard
              total={ratingData.data.data?.avgReting}
              title='Avg Reting'
              icon={DomainIcon}
              bgColor={primaryLighter}
              textColor={primaryDarker}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <DataCard
            total={totalPastTask}
            title='Task Evaluations'
            icon={TaskIcon}
            bgColor={primaryLighter}
            textColor={primaryDarker}
          />
        </Grid>
        {totalPastTask > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <DataCard
              total={`${submissionRate}%`}
              title='Task Submission Rate'
              icon={TaskIcon}
              bgColor={primaryLighter}
              textColor={primaryDarker}
            />
          </Grid>
        )}
        {totalPastTask > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <AppCurrentVisits
              heading='Submission Overview'
              label={submissionLabel}
              data={submissionData}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default BatchOverview;
