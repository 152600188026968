import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import InvoiceForm from './InvoiceForm';
import EditIcon from '@mui/icons-material/Edit';
import EditInvoice from './EditInvoice';

const Index = ({
  currentBatch,
  setInvoiceRaised,
  invoiceData,
  invoiceRaised,
  evalData,
}) => {
  const [edit, setEdit] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [openFinalInvoice, setFinalInvoice] = useState(false);

  const allInvoiceData = invoiceData?.data;

  const isFullTimeBatch =
    currentBatch?.timeSheet && currentBatch?.timeSheet?.startsAt;
  console.log(currentBatch);
  console.log(allInvoiceData, isFullTimeBatch);
  console.log(evalData);
  return (
    <div>
      {edit ? (
        <div>
          {dataToEdit && (
            <EditInvoice setEdit={setEdit} dataToEdit={dataToEdit} />
          )}
        </div>
      ) : (
        <div>
          {invoiceRaised ? (
            <>
              {allInvoiceData.map((currentInvoice) => {
                return (
                  <div>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      spacing={2}>
                      <Typography>
                        Invoice Status: {currentInvoice.status}
                      </Typography>
                      {currentInvoice.status === 'Pending' && (
                        <Button
                          onClick={() => {
                            setDataToEdit(currentInvoice);
                            setEdit(true);
                          }}>
                          <EditIcon />{' '}
                        </Button>
                      )}
                    </Stack>
                  </div>
                );
              })}
              <div>
                {isFullTimeBatch &&
                  allInvoiceData.length < 2 &&
                  evalData.attendanceUpdated && (
                    <div>
                      {openFinalInvoice ? (
                        <InvoiceForm
                          currentBatch={currentBatch}
                          setInvoiceRaised={setInvoiceRaised}
                          isSecondInvoice={true}
                        />
                      ) : (
                        <div>
                          {' '}
                          Please raise final invoice for if You have complete
                          Full Time Journey{' '}
                          <Button onClick={() => setFinalInvoice(true)}>
                            Click here
                          </Button>{' '}
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </>
          ) : (
            <InvoiceForm
              currentBatch={currentBatch}
              setInvoiceRaised={setInvoiceRaised}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
