import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';

import { format } from 'date-fns';

export default function BatchSummaryTable({ allBatch, setCurrentBatch }) {
  function currentWeek(startDate, week) {
    const dt1 = new Date(startDate);
    const dt2 = new Date();

    const totalDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    const myWeek = Math.ceil((totalDays + 1) / 7);
    return myWeek > week ? '_' : myWeek;
  }
  return (
    <TableContainer component={Card}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Batch</TableCell>
            <TableCell align='center'>Users</TableCell>
            <TableCell align='center'>Start Date</TableCell>
            <TableCell align='center'>Status</TableCell>
            <TableCell align='center'>Duration</TableCell>
            <TableCell align='center'>Current Week</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allBatch.map((batch) => (
            <TableRow
              hover
              key={batch._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
              }}
              onClick={() => setCurrentBatch(batch)}
            >
              <TableCell component='th' scope='row'>
                {batch.name}
              </TableCell>
              <TableCell align='center'>
                {batch.users && batch.users.length}
              </TableCell>
              <TableCell align='center'>
                {format(new Date(batch.startDate), 'do LLLL yyyy')}
              </TableCell>
              <TableCell align='center'>{batch.status}</TableCell>
              <TableCell align='center'>{batch.week} Week </TableCell>
              <TableCell align='center'>
                {currentWeek(batch.startDate, batch.week)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
