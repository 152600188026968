import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
// import bellFill from '@iconify/icons-eva/bell-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = [
  {
    title: 'Home',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'Batches',
    path: '/dashboard/batch',
    icon: getIcon(peopleFill),
  },
  {
    title: 'Tickets',
    path: '/dashboard/ticket',
    icon: getIcon(fileTextFill),
  },
  {
    title: 'Schedule',
    path: '/dashboard/schedule',
    icon: getIcon(calendarFill),
  },
  // {
  //   title: 'Announcements',
  //   path: '/dashboard/announcements',
  //   icon: getIcon(bellFill),
  // },
];

export default SidebarConfig;
