import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import ShowAttendance from './ShowAttendance';
import UpdateAttendance from './UpdateAttendance';

const MyEvent = ({ setCurrentEvent, currentEvent, currentBatch }) => {
  const [eventAttendance, setEventAttendance] = useState([]);
  const [loading, setLoading] = useState(true);
  const Authorization = localStorage.getItem('authorization');
  useEffect(() => {
    setEventAttendance([]);
    (async () => {
      const result = await axios(API_URL_V2 + '/event/attendance', {
        params: {
          eventId: currentEvent._id,
        },
        headers: { Authorization },
      });
      if (result.data) {
        setEventAttendance(result.data.data);
        setLoading(false);
      }
    })();
  }, [Authorization, setLoading, setEventAttendance, currentEvent]);

  return (
    <Box>
      <Button variant='outlined' onClick={() => setCurrentEvent(null)}>
        Back
      </Button>
      <Typography m={1}>{currentEvent.title}</Typography>
      {loading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      ) : eventAttendance.length ? (
        <ShowAttendance
          eventAttendance={eventAttendance}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
        />
      ) : (
        <UpdateAttendance
          users={currentBatch.users}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          currentBatch={currentBatch}
        />
      )}
    </Box>
  );
};

export default MyEvent;
