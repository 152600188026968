import React, { useState } from 'react';
import { Typography } from '@mui/material';

import AllBatches from './AllBatches';
import BatchDetails from './batchDetails';

const Index = () => {
  const [currentBatch, setCurrentBatch] = useState(null);
  return (
    <div>
      {currentBatch && (
        <Typography variant='h6' align='center'>
          {currentBatch.name}
        </Typography>
      )}
      {currentBatch ? (
        <BatchDetails
          currentBatch={currentBatch}
          setCurrentBatch={setCurrentBatch}
        />
      ) : (
        <AllBatches setCurrentBatch={setCurrentBatch} />
      )}
    </div>
  );
};

export default Index;
