import React, { useState } from 'react';
import { TableRow, TableCell, Button, Modal, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UploadScore from './UploadScore';
import { format } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  //   maxWidth: 800,
  minWidth: 350,
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const TaskDetails = ({ task, project, userSubmissionData }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableRow>
        <TableCell>{task.projectTask?.title || project.title}</TableCell>
        <TableCell>
          {task.projectTask?.dueDateTime &&
            format(new Date(task.projectTask?.dueDateTime), 'do LLLL yyyy')}
        </TableCell>
        <TableCell>
          {task?.submittedOn &&
            format(new Date(task?.submittedOn), 'do LLLL yyyy')}
        </TableCell>
        <TableCell> {task.score?.total} </TableCell>
        <TableCell>
          {' '}
          <Button onClick={() => setOpen(true)}>
            {task.coachNote ? <EditIcon /> : 'Upload Score'}
          </Button>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <UploadScore
            task={task}
            userSubmissionData={userSubmissionData}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default TaskDetails;
