import React, { useState } from 'react';
import BatchUser from './BatchUser';
import Profile from '../../../../profile/profile';

const Index = ({ users }) => {
  const [userEmail, setUserEmail] = useState(null);
  return (
    <div>
      {userEmail ? (
        <Profile email={userEmail} setUserEmail={setUserEmail} />
      ) : (
        <BatchUser users={users} setUserEmail={setUserEmail} />
      )}
    </div>
  );
};

export default Index;
