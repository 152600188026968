import React from 'react';

import { Container } from '@mui/material';
import Page from '../components/Page';
import Batch from '../components/_dashboard/batch';

const BatchApp = () => {
  return (
    <Page title='Batch'>
      <Container maxWidth='xl'>
        <Batch />
      </Container>
    </Page>
  );
};

export default BatchApp;
