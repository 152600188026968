import React from 'react';
import { styled } from '@mui/system';
import { format } from 'date-fns';

const MyComponent = styled('div')({
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  margin: '0 auto',
  borderRadius: 4,
  fontSize: 10,
  alignItems: 'center'
});


export default function Event({ event }) {
  return (
    <MyComponent style={{
      backgroundColor : event.type === 'task' ? '#D4F5EE' : '#10ADB9',
      color : event.type === 'task' ? 'black' : 'white',
    }}>
      <p>{event.title}</p>
      <p>
        {format(new Date(event.start), 'hh:mm')} -{' '}
        {format(new Date(event.end), 'hh:mm')}
      </p>
    </MyComponent>
  );
}
