import axios from 'axios';
import { API_URL } from '../../links/API_LINKS';
import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from './userTypes';

export const fetchUser = (cognitoId) => {
  return (dispatch) => {
    dispatch(fetchUserRequest());
    axios
      .get(API_URL + '/user', {
        params: { cognitoId: cognitoId },
      })
      .then((response) => {
        // response.data is the user
        const user = response.data;
        if (user === 'No user') {
          dispatch(fetchUserFailure('No user found'));
        } else {
          dispatch(fetchUserSuccess(user));
        }
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchUserFailure(error.message));
      });
  };
};

export const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

export const fetchUserSuccess = (user) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: user,
  };
};

export const fetchUserFailure = (error) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: error,
  };
};
