import React from 'react';
import { TextInput, SelectInput } from '../../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL_V2 } from '../../../../../links/API_LINKS';
import TextEditor from '../../../layouts/TextEditor';
import { useQueryClient } from 'react-query';

const Add = ({ currentBatch, setValue }) => {
  const Authorization = localStorage.getItem('authorization');
  const batchUser = currentBatch.users;
  const allAreas = [];
  batchUser.forEach((user) => {
    if (
      !allAreas.some((area) => {
        return user.area._id === area._id;
      })
    ) {
      allAreas.push(user.area);
    }
  });
  const allCompany = [];
  batchUser.forEach((user) => {
    if (
      !allCompany.some((company) => {
        return user.company._id === company._id;
      })
    ) {
      allCompany.push(user.company);
    }
  });
  const queryClient = useQueryClient();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        // innerRef={formikRef}
        initialValues={{
          batchId: currentBatch?._id,
          owner: 'Coach',
          fileUrl: '',
          title: '',
          message: '',
          companyId: 'All',
          areaId: 'All',
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          message: Yup.string().required('Message is required'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const newData = { ...values };
          newData.companyIds =
            values.companyId === 'All' ? [] : [values.companyId];
          newData.areaIds = values.areaId === 'All' ? [] : [values.areaId];
          delete newData.areaId;
          delete newData.companyId;
          axios({
            method: 'POST',
            url: API_URL_V2 + '/vga-announcement',
            data: newData,
            headers: { Authorization },
          })
            .then(function (response) {
              setSubmitting(false);
              resetForm();
              queryClient.invalidateQueries(
                `fetchAnnouncement${currentBatch._id}}`
              );
              setValue(0);
            })
            .catch(function (response) {
              setSubmitting(false);
            });
        }}
      >
        {({ values, getFieldProps, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 2, sm: 1, md: 3 }}
              mb={3}
              p={2}
            >
              <Grid item xs={12}>
                <TextInput
                  name='title'
                  type='text'
                  placeholder='Title'
                  label='Title'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1} variant='subtitle2'>
                  Announcement Message
                </Typography>
                <TextEditor
                  setFieldValue={setFieldValue}
                  initialtext=''
                  valueToUpdate='message'
                />
                <Typography mb={1} variant='string' color='error'>
                  {errors?.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1} variant='subtitle2'>
                  Select Audience for the announcement
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  name='companyId'
                  label='Select Company'
                >
                  <MenuItem value='All'>All</MenuItem>
                  {allCompany.map((company) => {
                    return (
                      <MenuItem value={company._id} key={company._id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInput size='small' name='areaId' label='Select Area'>
                  <MenuItem value='All'>All</MenuItem>
                  {allAreas.map((area) => {
                    return (
                      <MenuItem value={area._id} key={area._id}>
                        {area.title}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Add;
