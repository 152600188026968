import React from 'react';

import { Container } from '@mui/material';
import Page from '../components/Page';
import Home from '../components/_dashboard/home';

const DashboardApp = () => {
  return (
    <Page title='Home'>
      <Container maxWidth='xl'>
        <Home />
      </Container>
    </Page>
  );
};

export default DashboardApp;
