import React from 'react';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';

const EditAttendance = ({ attendance, currentEvent, setCurrentEvent }) => {
  const Authorization = localStorage.getItem('authorization');

  const formik = useFormik({
    initialValues: {
      eventId: currentEvent._id,
      attendance: attendance,
    },
    onSubmit: () => {
      axios({
        method: 'POST',
        url: API_URL_V2 + '/event/attendance',
        data: values,
        headers: { Authorization },
      })
        .then(function (response) {
          setSubmitting(false);
          resetForm();
          setCurrentEvent(null);
        })
        .catch(function (response) {
          setSubmitting(false);
        });
    },
  });
  const {
    isSubmitting,
    setSubmitting,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <TableContainer component={Card}>
          <Table
            sx={{ minWidth: 450, borderRadius: 2, shadow: 2 }}
            aria-label='table'
          >
            <TableHead>
              <TableRow>
                <TableCell> Name</TableCell>
                <TableCell align='center'>Email</TableCell>
                <TableCell align='center'>Attendance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendance.map((user, index) => (
                <TableRow hover key={index}>
                  <TableCell component='th' scope='row'>
                    {user.name}
                  </TableCell>
                  <TableCell align='center'>{user.email}</TableCell>

                  <TableCell align='right'>
                    <RadioGroup
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      defaultValue={values.attendance[index].status}
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          setFieldValue(
                            `attendance[${index}].status`,
                            parseInt(e.target.value)
                          );
                        }}
                        value={0}
                        control={<Radio />}
                        label='A'
                      />
                      <FormControlLabel
                        onChange={(e) => {
                          setFieldValue(
                            `attendance[${index}].status`,
                            parseInt(e.target.value)
                          );
                        }}
                        value={1}
                        control={<Radio />}
                        label='P'
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
          }}
        >
          <LoadingButton
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default EditAttendance;
