import React from 'react';
import { Container } from '@mui/material';
import AnnouncementTabs from './Tabs';

const Index = ({ currentBatch }) => {
  const [value, setValue] = React.useState(0);
  return (
    <Container>
      <AnnouncementTabs
        value={value}
        setValue={setValue}
        currentBatch={currentBatch}
      />
    </Container>
  );
};

export default Index;
