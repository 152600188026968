import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  TextField,
  Typography,
  Stack,
  Grid,
  Alert,
  AlertTitle,
} from '@mui/material';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';
import htmlparser from 'html-react-parser';

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField
        label={label}
        {...field}
        {...props}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
      />
    </>
  );
};

const UploadScore = ({ task, userSubmissionData, handleClose }) => {
  const { score, coachNote, taskId } = task;
  const { userId, projectId } = userSubmissionData;
  const Authorization = localStorage.getItem('authorization');
  const [totalScore, setTotalScore] = useState(0);
  const queryClient = useQueryClient();
  const getTotalScore = function (arr) {
    var totalSum = 0;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] !== '' && arr[i] !== null) {
        totalSum += arr[i];
      }
    }
    setTotalScore(totalSum);

    return totalSum;
  };
  return (
    <div>
      <Box m={4}>
        <Typography variant='subtitle2'>Task Instructions</Typography>
        <Typography>
          {task.projectTask?.taskInstructions &&
            htmlparser(task.projectTask?.taskInstructions)}
        </Typography>
        <Typography variant='subtitle2' mt={2}>
          User Solution
        </Typography>
        <Typography>{task.solution && htmlparser(task.solution)}</Typography>
      </Box>
      <Formik
        enableReinitialize={true}
        // innerRef={formikRef}
        initialValues={{
          vgaSubmissionId: userSubmissionData._id,
          taskId: taskId,
          score: {
            problemStatementAnalysis: score?.problemStatementAnalysis || 0,
            potentialSolutionsEvaluation:
              score?.potentialSolutionsEvaluation || 0,
            solutionStrategy: score?.solutionStrategy || 0,
            challangeAddressal: score?.challangeAddressal || 0,
            outcomeEvaluation: score?.outcomeEvaluation || 0,
            total: score?.total || 0,
          },
          coachNote: coachNote || '',
        }}
        validationSchema={Yup.object({
          coachNote: Yup.string().required().min(50),
          score: Yup.object().shape({
            problemStatementAnalysis: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            potentialSolutionsEvaluation: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            solutionStrategy: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            challangeAddressal: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
            outcomeEvaluation: Yup.number()
              .min(0, 'Min value 0.')
              .max(20, 'Max value 20.')
              .required(),
          }),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          values.score.total = totalScore;
          axios({
            method: 'POST',
            url: API_URL_V2 + '/vga-submission/add-score',
            data: values,
            headers: { Authorization },
          })
            .then(function (response) {
              queryClient.invalidateQueries(
                `fetchUserSubmission${userId}-${projectId}`
              );
              setSubmitting(false);
              handleClose();
            })
            .catch(function (response) {
              setSubmitting(false);
            });
        }}
      >
        {({ values, getFieldProps, setFieldValue, isSubmitting }) => (
          <Form>
            <Typography m={2}>
              {/* Uploading Score for {currentUser.name} */}
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={2} mb={3}>
              <Grid item xs={12} sm={6}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <TextInput
                    name='score.problemStatementAnalysis'
                    type='number'
                    placeholder='Problem Statement Analysis'
                    label='Problem Statement Analysis'
                  />
                  <Typography>/20</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <TextInput
                    name='score.potentialSolutionsEvaluation'
                    type='number'
                    placeholder='Potential Solutions Evaluation'
                    label='Potential Solutions Evaluation'
                  />
                  <Typography>/20</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <TextInput
                    name='score.solutionStrategy'
                    type='number'
                    placeholder='Solution Startegy and Implementation'
                    label='Solution Startegy and Implementation'
                  />
                  <Typography>/20</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                {' '}
                <Stack direction='row' alignItems='center' spacing={2}>
                  <TextInput
                    name='score.challangeAddressal'
                    type='number'
                    placeholder='Challenge Addressal'
                    label='Challenge Addressal'
                  />
                  <Typography>/20</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <TextInput
                    name='score.outcomeEvaluation'
                    type='number'
                    placeholder='Outcome Evaluation'
                    label='Outcome Evaluation'
                  />
                  <Typography>/20</Typography>
                </Stack>
              </Grid>

              <Typography m={2}>
                Total score :
                {getTotalScore([
                  values.score.problemStatementAnalysis,
                  values.score.potentialSolutionsEvaluation,
                  values.score.solutionStrategy,
                  values.score.challangeAddressal,
                  values.score.outcomeEvaluation,
                ])}
              </Typography>

              <Grid item xs={6}>
                <Alert severity='info'>
                  <AlertTitle>
                    Quick Tips: Insights that you can give feedback on
                  </AlertTitle>
                  - Include imrpovement suggestions
                  <br /> - Highlight the assignment's strengths and weaknesses
                  <br /> - Provide actionable feedback
                  <br />- Give next steps guidance in case needed
                </Alert>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  name='coachNote'
                  type='text'
                  label='Note'
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UploadScore;
