import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import axios from 'axios';
import MyTickets from './MyTickets';
import TicketDetails from './TicketDetails';

import { API_URL_V2 } from '../../../../../links/API_LINKS';

const Index = ({ coach, batchId, populateBatch }) => {
  const [ticketData, setTicketData] = useState([]);
  const [ticketType, setTicketType] = useState('open');
  const [ticketTypeData, setTicketTypeData] = useState([]);
  const [ticketDetails, setTicketDetails] = useState(false);
  const [updateTicket, setUpdateTicket] = useState(false);
  const [loading, setLoading] = useState(true);
  const Authorization = localStorage.getItem('authorization');

  useEffect(() => {
    const myCoach =
      coach.coach && coach.coach.length === 1 ? coach.coach[0] : null;

    if (myCoach) {
      (async () => {
        const result = await axios(API_URL_V2 + '/ticket', {
          params: {
            assignee: myCoach._id,
            batchId: batchId,
            populateBatch: populateBatch,
            limit: 0,
          },
          headers: { Authorization },
        });
        if (result.data) {
          setTicketData(result.data.data);
          setLoading(false);
        }
      })();
    }
  }, [
    coach,
    setTicketData,
    populateBatch,
    updateTicket,
    batchId,
    Authorization,
  ]);

  useEffect(() => {
    const myData = ticketData.filter((ticket) => {
      return ticket.status === ticketType;
    });
    setTicketTypeData(myData);
  }, [ticketType, setTicketTypeData, ticketData]);

  return (
    <div>
      {loading && !ticketData.length ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      ) : ticketDetails ? (
        <TicketDetails
          ticketDetails={ticketDetails}
          setTicketDetails={setTicketDetails}
          setUpdateTicket={setUpdateTicket}
        />
      ) : (
        <>
          <Box>
            <Button
              onClick={() => setTicketType('open')}
              variant={ticketType === 'open' ? 'contained' : 'outlined'}
              sx={{ mr: 2 }}
            >
              Open
            </Button>
            <Button
              onClick={() => setTicketType('closed')}
              variant={ticketType === 'closed' ? 'contained' : 'outlined'}
            >
              Closed
            </Button>
          </Box>
          <MyTickets
            ticketTypeData={ticketTypeData}
            setTicketDetails={setTicketDetails}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coach: state.coach,
  };
};

export default connect(mapStateToProps)(Index);
