import { Box, Typography, Button } from '@mui/material';
import { Formik, Field, Form } from 'formik';
// import Navigation from "./Navigation";
// import Navigation from './Navigation';
// import { useAddVgaFeedbackMutation } from './vgaFeedbackSlice';
import axios from 'axios';

import { API_URL_V2 } from '../../../../../../links/API_LINKS';

const Questions = (props) => {
  const CheckIfAllAnswered = (values) => {
    for (const question of values.data) {
      if (question.value === '') {
        return false;
      }
    }

    return true;
  };

  return (
    <Formik
      initialValues={{ data: props.database }}
      onSubmit={(values) => {
        const dataToSend = { batchId: props.batchId };
        values.data.forEach((individual) => {
          if (individual.questionType === 'radio') {
            //convert string to number
            individual.value = +individual.value;
          }
        });
        dataToSend['feedback'] = values.data;

        const Authorization = localStorage.getItem('authorization');

        axios({
          method: 'PUT',
          url: API_URL_V2 + '/batch/coach-feedback',
          data: dataToSend,
          headers: {
            Authorization,
          },
        })
          .then(function (res) {
            props.setFeedbackSubmitted(true);
          })
          .catch((err) => {
            console.log(err);
          });

        // addVgaFeedback(dataToSend)
        //   .unwrap()
        //   .then((res) => {
        //     window.location.reload();
        //   });
      }}>
      {({ values, index, setFieldValue, isSubmitting }) => (
        <Form>
          <Box key={index} display='flex' flexDirection='column' gap={2}>
            {props.database.map((ques, idx) => (
              <Box key={idx}>
                <Typography sx={{ paddingBottom: 0.5 }}>
                  {ques.question}
                </Typography>
                <Box display='flex' flexDirection='row' gap={2}>
                  {ques.questionType === 'radio' ? (
                    ['1', '2', '3', '4', '5'].map((val, radioIdx) => (
                      <Box
                        key={radioIdx}
                        display='flex'
                        alignItems='center'
                        gap={0.5}>
                        <Field
                          type={ques.questionType}
                          id={`${idx}_${radioIdx}`}
                          name={`data[${idx}].value`}
                          value={val}
                        />
                        <label htmlFor={`${idx}_${radioIdx}`}>{val}</label>
                      </Box>
                    ))
                  ) : (
                    <Field
                      as='textarea'
                      type={ques.questionType}
                      name={`data[${idx}].value`}
                      required
                      style={{
                        width: '100%',
                        height: '100px',
                        padding: '4px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            ))}
            {/* <Typography variant="h5" sx={{ paddingBottom: 2 }}>
                            {question.question}
                        </Typography>
                        <div>
                            {question.questionType === "radio"
                                ? radioInputs
                                : null}
                            {question.questionType === "text"
                                ? textInputs
                                : null}
                        </div> */}
          </Box>
          <Button
            variant='outlined'
            type='submit'
            sx={{ marginTop: 2 }}
            disabled={!CheckIfAllAnswered(values)}>
            Submit
          </Button>
          {/* <Navigation
                        values={values}
                        questionName={question.question}
                        database={props.database}
                        currentQuestion={props.currentQuestion}
                        setCurrentQuestion={props.setCurrentQuestion}
                        isSubmitting={isSubmitting}
                        setIsInvoiceOpen={props.setIsInvoiceOpen}
                    /> */}
        </Form>
      )}
    </Formik>
  );
};

export default Questions;
