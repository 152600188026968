import React, { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { API_URL_V2 } from '../../../../../links/API_LINKS';

const EditReply = (props) => {
  const comment = props.comment;

  const ReplySchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
  });
  const Authorization = localStorage.getItem('authorization');

  const formik = useFormik({
    initialValues: {
      ticketId: props.ticketId,
      comment: props.comment,
      commentIndex: props.index,
    },
    validationSchema: ReplySchema,
    onSubmit: () => {
      axios({
        method: 'PUT',
        url: API_URL_V2 + '/ticket/comment/edit',
        data: values,
        headers: { Authorization },
      })
        .then(function (response) {
          setSubmitting(false);
          props.setUpdateTicket((prev) => !prev);
          resetForm();
          props.setTicketDetails(false);
        })
        .catch(function (response) {
          setSubmitting(false);
        });
    },
  });
  const {
    isSubmitting,
    setSubmitting,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formik;
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: [
        ['bold'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }],
        ['clean'],
      ],
    },
    formats: ['bold', 'list', 'link', 'color', 'clean'],
  });
  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = comment;
      quill.on('text-change', () => {
        setFieldValue('comment', quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, setFieldValue, comment]);

  if (Quill && !quill) {
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box sx={{ height: 250 }} mb={6}>
            <div ref={quillRef} />
          </Box>
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            Update Reply
          </LoadingButton>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default EditReply;
