import React from 'react';
import { formatDistance } from 'date-fns';
import { Box, Stack, Typography, Container, Card } from '@mui/material';

function NewsItem({ ticket }) {
  const { subject, description, user } = ticket;
  const createdAt = formatDistance(new Date(ticket.createdAt), new Date(), {
    addSuffix: true,
  });

  const batchName =
    ticket.batch && ticket.batch.name ? ticket.batch.name : null;

  return (
    <>
      <Stack direction='row' alignItems='center' spacing={2}>
        <Box sx={{ minWidth: 240 }}>
          <Typography variant='subtitle2' noWrap>
            {subject}
          </Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>
        </Box>
        <Typography
          variant='caption'
          sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}
        >
          {createdAt}
        </Typography>
        {batchName && (
          <Typography
            variant='caption'
            sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}
          >
            {batchName}
          </Typography>
        )}
      </Stack>
      <Typography variant='caption'>Asked by {user.name} </Typography>
    </>
  );
}

const MyTickets = ({ ticketTypeData, setTicketDetails }) => {
  return (
    <div>
      <Container>
        <Typography variant='h4' paddingBottom={1} sx={{ mt: 2 }}>
          My Recent Queries
        </Typography>
      </Container>

      <Stack spacing={1} sx={{ p: 1, pr: 0 }}>
        {ticketTypeData.map((ticket) => {
          const startTime = new Date(ticket.createdAt);
          const nowTime = new Date();
          const timediff = Math.abs(startTime - nowTime) / 3600000;

          const onWarning =
            ticket.status === 'open' && timediff > 18 && timediff < 24;
          const lateWarning = ticket.status === 'open' && timediff > 24;

          return (
            <Stack
              component={Card}
              key={ticket._id}
              sx={{
                cursor: 'pointer',
                p: 2,
                bgcolor:
                  (onWarning && '#FFFF99') || (lateWarning && '#ffcccb') || '',

                '&:hover': {
                  background: '#F5F5F5',
                },
              }}
              onClick={() => setTicketDetails(ticket)}
            >
              <NewsItem ticket={ticket} />
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
};

export default MyTickets;
