import React from 'react';

import { Box } from '@mui/material';

import Projects from './projects';

const Index = ({ currentBatch }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Projects currentBatch={currentBatch} />
    </Box>
  );
};

export default Index;
