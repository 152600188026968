import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Avatar,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { API_URL_V2, UPLOAD_OR_DOWNLOAD_URL } from '../../../links/API_LINKS';
import axios from 'axios';

const useStyle = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(25),
    width: theme.spacing(25),
  },
  border: {
    padding: '30px',
    backgroundColor: '#f5f5f5',
  },
  logo: {
    height: theme.spacing(10),
    width: theme.spacing(30),
  },
}));

const Profile = ({ email, setUserEmail }) => {
  const classes = useStyle();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const Authorization = localStorage.getItem('authorization');

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL_V2 + '/user', {
        params: {
          email: email,
        },
        headers: { Authorization },
      });
      if (result.data) {
        setUserData(result.data.data);
        setLoading(false);
      }
    })();
  }, [setUserData, setLoading, email, Authorization]);

  return (
    <Container className={classes.root}>
      {loading ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Button onClick={() => setUserEmail(null)} variant='contained'>
            <ArrowBackIosIcon />
          </Button>
          <br />
          <br />
          <Grid className={classes.border} container>
            <Grid
              item
              container
              direction='column'
              justify='center'
              alignItems='center'
              xs={3}
            >
              <Avatar
                src={
                  userData.imageUrl
                    ? UPLOAD_OR_DOWNLOAD_URL + '/' + userData.imageUrl
                    : '#'
                }
                className={classes.avatar}
              />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12} className={classes.border}>
              <Typography variant='h4' gutterBottom>
                Details
              </Typography>
              <Grid container>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography variant='subtitle1' gutterBottom>
                    Full Name : {userData.name}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography variant='subtitle1' gutterBottom>
                    Email : {userData.email}
                  </Typography>
                </Grid>
                {userData.contactNumber && (
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='subtitle1' gutterBottom>
                      Contact No : {userData.contactNumber}
                    </Typography>
                  </Grid>
                )}
                <Grid item lg={6} md={6} xs={12}>
                  <Typography variant='subtitle1' gutterBottom>
                    Country : {userData.country}
                  </Typography>
                </Grid>
                {userData.city && (
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='subtitle1' gutterBottom>
                      City : {userData.city}
                    </Typography>
                  </Grid>
                )}
                {userData.jobtitle && (
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='subtitle1' gutterBottom>
                      Job Title : {userData.jobtitle}
                    </Typography>
                  </Grid>
                )}
                {userData.course && (
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='subtitle1' gutterBottom>
                      Course : {userData.course}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* 
          <Grid container className={classes.border}>
            <Grid item xs={12}>
              <Typography variant='h5'>About Your Company</Typography>
              <br />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <img
                src='http://res.cloudinary.com/belong/image/upload/v1638202021/uploaded_resources/Belong_nrxqqw.png'
                className={classes.logo}
                alt='logo'
              />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Typography variant='body2' color='textSecondary'>
                We enable talent to go global from their rooms. Belong is an
                employability marketplace that facilitates a seamless ecosystem
                of opportunities for young talent, global employers and
                universities across the globe.
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.border}>
            <Grid item lg={6} md={6} xs={12}>
              <Typography variant='h5'>Your Journey</Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Typography variant='body2' color='textSecondary'></Typography>
            </Grid>
          </Grid>

          <Grid>
            <Typography variant='h5' className={classes.border}>
              Your Assesment Scores
            </Typography>
          </Grid> */}
        </>
      )}
    </Container>
  );
};

export default Profile;
