import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, Box, Button, MenuItem } from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';

import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import { SelectInput, TextInput } from '../../../../layouts/FormElement';

const InvoiceForm = (props) => {
  const InvoiceSchema = Yup.object().shape({
    totalAmount: Yup.number().required('Total Amount is required.'),
    sessions: Yup.number().required('Session Count is required.'),
    currency: Yup.string().required('Currency is required.'),
    bankDetails: Yup.object().shape({
      bankName: Yup.string().when('$currency', {
        is: 'RAND',
        then: Yup.string().required(
          'Bank name is required when currency is RAND.'
        ),
        otherwise: Yup.string(),
      }),
      accountHolderName: Yup.string().when('$currency', {
        is: 'RAND',
        then: Yup.string().required(
          'Account holder name is required when currency is RAND.'
        ),
        otherwise: Yup.string(),
      }),
      accountNumber: Yup.string().when('$currency', {
        is: 'RAND',
        then: Yup.string().required(
          'Account Number is required when currency is RAND.'
        ),
        otherwise: Yup.string(),
      }),
      IFSC_Code: Yup.string().when('$currency', {
        is: 'RAND',
        then: Yup.string().required(
          'IFSC Code is required when currency is RAND.'
        ),
        otherwise: Yup.string(),
      }),
      UPI_Id: Yup.string().when('$currency', {
        is: 'INR',
        then: Yup.string().required('UPI Id is required when currency is INR.'),
        otherwise: Yup.string(),
      }),
    }),
  });

  return (
    <Formik
      initialValues={{
        batchId: props.currentBatch._id,
        totalAmount: '',
        currency: 'INR',
        sessions: '',
        bankDetails: {
          bankName: '',
          accountHolderName: '',
          accountNumber: '',
          IFSC_Code: '',
          UPI_Id: '',
        },
      }}
      validationSchema={InvoiceSchema}
      // validateOnChange={true}
      // validateOnBlur={true}
      onSubmit={(values) => {
        // props.setIsInvoiceOpen(true);
        const Authorization = localStorage.getItem('authorization');

        axios({
          method: 'POST',
          url: API_URL_V2 + '/invoice',
          data: values,
          headers: {
            Authorization,
          },
        })
          .then(function (res) {
            props.setInvoiceRaised(true);
            if (props.isSecondInvoice) {
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }}>
      {({ values, index, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextInput
                name='sessions'
                label={'Number of Sessions *'}
                type='number'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='totalAmount'
                label={'Total amount *'}
                type='number'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <SelectInput name='currency' label='Select Currency'>
                  <MenuItem value='INR'>INR</MenuItem>
                  <MenuItem value='RAND'>RAND</MenuItem>
                </SelectInput>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='bankDetails.bankName'
                label={'Bank Name *'}
                type='text'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='bankDetails.accountHolderName'
                label={'Account Holder Name *'}
                type='text'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='bankDetails.accountNumber'
                label={'Bank Account Number *'}
                type='number'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='bankDetails.IFSC_Code'
                label={'Bank IFSC Code *'}
                type='text'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='bankDetails.UPI_Id'
                label={'UPI Id'}
                type='text'
                style={{
                  width: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Button
            sx={{ marginTop: 2 }}
            variant='outlined'
            disabled={isSubmitting}
            type='submit'>
            {isSubmitting ? 'Submiting...' : 'Submit'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default InvoiceForm;
