import axios from 'axios';
import {
  FETCH_COACHDASHBOARD_REQUEST,
  FETCH_COACHDASHBOARD_SUCCESS,
  FETCH_COACHDASHBOARD_FAILURE,
} from './coachDashboardTypes';
import { API_URL_V2 } from '../../links/API_LINKS';

export const fetchCoachDashboardDetails = () => {
  const Authorization = localStorage.getItem('authorization');
  return (dispatch) => {
    dispatch(fetchCoachDashboardRequest());
    axios
      .get(API_URL_V2 + '/dashboard/coach', {
        headers: { Authorization },
      })
      .then((response) => {
        const coachDashboard = response.data;
        dispatch(fetchCoachDashboardSuccess(coachDashboard));
      })
      .catch((error) => {
        dispatch(fetchCoachDashboardFailure(error.message));
      });
  };
};

export const fetchCoachDashboardRequest = () => {
  return {
    type: FETCH_COACHDASHBOARD_REQUEST,
  };
};

export const fetchCoachDashboardSuccess = (coachDashboard) => {
  return {
    type: FETCH_COACHDASHBOARD_SUCCESS,
    payload: coachDashboard,
  };
};

export const fetchCoachDashboardFailure = (error) => {
  return {
    type: FETCH_COACHDASHBOARD_FAILURE,
    payload: error,
  };
};
