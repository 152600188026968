import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import BatchUser from './batchUser';
import BatchOverview from './batchOverview/BatchOverview';
import UserJourney from './userJourney';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = ({ currentBatch }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Batch' {...a11yProps(0)} />
          <Tab label='Learners' {...a11yProps(1)} />
          <Tab label='User Journey' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <BatchOverview currentBatch={currentBatch} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BatchUser users={currentBatch.users} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserJourney batchId={currentBatch._id} />
      </TabPanel>
    </Box>
  );
};

export default Index;
