import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { connect } from 'react-redux';
import TicketInfo from './TicketInfo';
import Ticket from '../batch/batchDetails/ticket';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = ({ coach }) => {
  const [value, setValue] = useState(0);
  const [coachId, setCoachId] = useState(null);
  useEffect(() => {
    const myCoachId =
      coach.coach && coach.coach.length === 1 ? coach.coach[0]._id : null;
    if (myCoachId) {
      setCoachId(myCoachId);
    }
  }, [coach]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Overview' {...a11yProps(0)} />
          <Tab label='Ticket' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {coachId && <TicketInfo coachId={coachId} />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Ticket populateBatch={true} />
      </TabPanel>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    coach: state.coach,
  };
};

export default connect(mapStateToProps)(Index);
