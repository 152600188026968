import React from 'react';

import { Container } from '@mui/material';
import Page from '../components/Page';
import Ticket from '../components/_dashboard/ticket';

const TicketApp = () => {
  return (
    <Page title='Ticket'>
      <Container maxWidth='xl'>
        <Ticket />
      </Container>
    </Page>
  );
};

export default TicketApp;
