import React from 'react';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from 'axios';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import { Box } from '@mui/system';

const AddAttendance = ({
  users,
  currentEvent,
  setCurrentEvent,
  audienceDetail,
  currentBatch,
}) => {
  const CheckEligibility = (arrayOfCriteria, usersIdForThatCriteria) => {
    if (
      !arrayOfCriteria.length ||
      arrayOfCriteria.includes(usersIdForThatCriteria._id)
    ) {
      return true;
    }
    return false;
  };

  let myAttendance = [];

  users.forEach((user) => {
    if (
      currentBatch.timeSheet &&
      currentEvent.week >= currentBatch.timeSheet.startsAt
    ) {
      CheckEligibility(audienceDetail.areas, user.area) &&
        CheckEligibility(audienceDetail.coachId, user.coach2 || user.coach) &&
        CheckEligibility(
          audienceDetail.mentorId,
          user.mentor2 || user.mentor
        ) &&
        CheckEligibility(
          audienceDetail.companyId,
          user.company2 || user.company
        ) &&
        user.status === 'Active' &&
        myAttendance.push({
          name: user.name,
          userId: user.user,
          email: user.email,
          status: 1,
        });
    } else {
      CheckEligibility(audienceDetail.areas, user.area) &&
        CheckEligibility(audienceDetail.coachId, user.coach) &&
        CheckEligibility(audienceDetail.mentorId, user.mentor) &&
        CheckEligibility(audienceDetail.companyId, user.company) &&
        user.status === 'Active' &&
        myAttendance.push({
          name: user.name,
          userId: user.user,
          email: user.email,
          status: 1,
        });
    }
  });

  const Authorization = localStorage.getItem('authorization');

  const formik = useFormik({
    initialValues: {
      eventId: currentEvent._id,
      attendance: myAttendance,
    },
    onSubmit: () => {
      axios({
        method: 'POST',
        url: API_URL_V2 + '/event/attendance',
        data: values,
        headers: { Authorization },
      })
        .then(function (response) {
          setSubmitting(false);
          resetForm();
          setCurrentEvent(null);
        })
        .catch(function (response) {
          setSubmitting(false);
        });
    },
  });
  const {
    isSubmitting,
    setSubmitting,
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <TableContainer component={Card}>
          <Table
            sx={{ minWidth: 450, borderRadius: 2, shadow: 2 }}
            aria-label='table'>
            <TableHead>
              <TableRow>
                <TableCell> Name</TableCell>
                <TableCell align='center'>Email</TableCell>
                <TableCell align='center'>Attendance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myAttendance.map((user, index) => (
                // add status condition here
                <TableRow hover key={index}>
                  <TableCell component='th' scope='row'>
                    {user.name}
                  </TableCell>
                  <TableCell align='center'>{user.email}</TableCell>

                  <TableCell align='right'>
                    <RadioGroup
                      sx={{ display: 'flex', flexDirection: 'row' }}
                      defaultValue={1}>
                      <FormControlLabel
                        onChange={(e) => {
                          setFieldValue(
                            `attendance[${index}].status`,
                            parseInt(e.target.value)
                          );
                        }}
                        value={0}
                        control={<Radio />}
                        label='A'
                      />
                      <FormControlLabel
                        onChange={(e) => {
                          setFieldValue(
                            `attendance[${index}].status`,
                            parseInt(e.target.value)
                          );
                        }}
                        value={1}
                        control={<Radio />}
                        label='P'
                      />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
          }}>
          <LoadingButton
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default AddAttendance;
