import React from 'react';
import { Grid, Container } from '@mui/material';
import DataCard from '../layouts/DataCard';
import DailySchedule from '../layouts/DailySchedule';

import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import DomainIcon from '@mui/icons-material/Domain';

import { API_URL_V2 } from '../../../links/API_LINKS';
import axios from 'axios';
import { useQuery } from 'react-query';

const primaryLighter = '#C8FACD';
const infoLighter = '#D0F2FF';
const warningLighter = '#FFF7CD';
const primaryDarker = '#005249';
const infoDarker = '#04297A';
const warningDarker = '#7A4F01';

const Overview = ({ data }) => {
  const Authorization = localStorage.getItem('authorization');
  const fetchRating = async () => {
    const res = await axios.get(API_URL_V2 + '/dashboard/coach/event-rating', {
      headers: { Authorization },
    });
    return res;
  };

  const { status, data: ratingData } = useQuery('fetchRating', fetchRating);
  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={data.batchesCount}
            title='No of Batches'
            icon={DomainIcon}
            bgColor={primaryLighter}
            textColor={primaryDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={data.usersCount}
            title='No of Learners'
            icon={PeopleIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={data.sessionsCount}
            title='No. of Sessions Lead'
            icon={SchoolIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
        {status === 'success' && ratingData.data.data && (
          <Grid item xs={12} sm={6} md={3}>
            <DataCard
              total={ratingData.data.data?.avgReting}
              title='Avg Reting'
              icon={DomainIcon}
              bgColor={primaryLighter}
              textColor={primaryDarker}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <DailySchedule />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Overview;
