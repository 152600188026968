import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { Box } from '@mui/material';

const TextEditor = ({ setFieldValue, valueToUpdate, initialtext }) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }],
        ['clean'],
      ],
    },
    formats: ['bold', 'list', 'link', 'color', 'clean', 'italic', 'size'],
  });
  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = initialtext;
      quill.on('text-change', () => {
        setFieldValue(valueToUpdate, quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, setFieldValue, initialtext, valueToUpdate]);

  if (Quill && !quill) {
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }
  return (
    <Box sx={{ height: 150 }} mb={8}>
      <div ref={quillRef} />
    </Box>
  );
};

export default TextEditor;
