import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Collapse,
  Table,
} from '@mui/material';
import { useQuery } from 'react-query';
import { API_URL_V2 } from '../../../../../../links/API_LINKS';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import TaskDetails from './TaskDetails';

const UserTaskDetails = ({ user, project, currentBatch }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const userId = user.user;
  const projectId = project._id;
  const batchId = project.batchId;
  const Authorization = localStorage.getItem('authorization');
  const fetchUserSubmission = async () => {
    const res = await axios.get(API_URL_V2 + '/vga-submission', {
      params: { projectId, batchId, userId },
      headers: { Authorization },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchUserSubmission${userId}-${projectId}`,
    fetchUserSubmission,
    { select: (data) => data?.data?.data }
  );
  let taskDone = 0;
  let unCheckTasks = 0;
  let userTasks = [];

  if (status === 'success' && data) {
    taskDone = data.tasksubmission?.length;
    const checkedTask = data.tasksubmission?.filter((task) => {
      return task.coachNote;
    }).length;
    unCheckTasks = taskDone - checkedTask;
    userTasks = data.tasksubmission.map((userTask) => {
      const projectTask = project.tasks.filter((task) => {
        return task._id === userTask.taskId;
      })[0];
      return { ...userTask, projectTask };
    });
  }

  return (
    <>
      <TableRow
        hover
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          cursor: 'pointer',
        }}>
        <TableCell
          //sx={{ backgroundColor: bgColor }}
          component='th'
          scope='row'>
          {data && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setCollapseOpen(!collapseOpen)}>
              {collapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          {user.name}
        </TableCell>
        <TableCell align='center'>{user.email}</TableCell>
        <TableCell align='center'>{taskDone}</TableCell>
        <TableCell align='center'>{unCheckTasks}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label=''>
                <TableHead>
                  <TableRow>
                    <TableCell>Task Title</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Submitted On</TableCell>
                    <TableCell>Score</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userTasks.map((task, index) => {
                    return (
                      <TaskDetails
                        key={index}
                        task={task}
                        project={project}
                        userSubmissionData={data}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserTaskDetails;
