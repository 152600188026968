import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import fetchUserReducer from './user/fetchUserReducer';
import coachReducer from './coach/coachReducer';
import coachBatchReducer from './coachBatch/coachBatchReducer';
import coachDashboardReducer from './coachDashboard/coachDashboardReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: fetchUserReducer,
  coach: coachReducer,
  coachBatch: coachBatchReducer,
  coachDashboard: coachDashboardReducer,
});

export default rootReducer;
