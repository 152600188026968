import React, { useState } from 'react';
import AllEvents from './AllEvents';
import MyEvent from './MyEvent';

const Index = ({ currentBatch }) => {
  const [currentEvent, setCurrentEvent] = useState(null);

  return (
    <div>
      {currentEvent ? (
        <MyEvent
          currentBatch={currentBatch}
          setCurrentEvent={setCurrentEvent}
          currentEvent={currentEvent}
        />
      ) : (
        <AllEvents
          allEvent={currentBatch.events}
          setCurrentEvent={setCurrentEvent}
        />
      )}
    </div>
  );
};

export default Index;
