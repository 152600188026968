import axios from 'axios';
import {
  FETCH_COACHBATCH_REQUEST,
  FETCH_COACHBATCH_SUCCESS,
  FETCH_COACHBATCH_FAILURE,
} from './coachBatchTypes';
import { API_URL_V2 } from '../../links/API_LINKS';

export const fetchCoachBatchDetails = () => {
  const Authorization = localStorage.getItem('authorization');
  return (dispatch) => {
    dispatch(fetchCoachBatchRequest());
    axios
      .get(API_URL_V2 + '/coach/batches', {
        headers: { Authorization },
        params: {
          sort: 'startDate',
          order: 'desc',
        },
      })
      .then((response) => {
        const coachBatch = response.data;
        dispatch(fetchCoachBatchSuccess(coachBatch));
      })
      .catch((error) => {
        dispatch(fetchCoachBatchFailure(error.message));
      });
  };
};

export const fetchCoachBatchRequest = () => {
  return {
    type: FETCH_COACHBATCH_REQUEST,
  };
};

export const fetchCoachBatchSuccess = (coachBatch) => {
  return {
    type: FETCH_COACHBATCH_SUCCESS,
    payload: coachBatch,
  };
};

export const fetchCoachBatchFailure = (error) => {
  return {
    type: FETCH_COACHBATCH_FAILURE,
    payload: error,
  };
};
