import { Box, Typography } from '@mui/material';
import React from 'react';

const EventFeedback = ({ feedbacks }) => {
  const total = feedbacks.length;
  const avgReting =
    feedbacks.reduce((total, next) => total + next.feedback.reting, 0) / total;
  const userComment = feedbacks.map((fed) => {
    return fed.feedback.text;
  });

  const commentCount = {};
  userComment.forEach((element) => {
    if (commentCount[element]) {
      commentCount[element] += 1;
    } else {
      commentCount[element] = 1;
    }
  });

  return (
    <Box m={2}>
      <Typography>Average Cohort Interaction - {avgReting} / 5</Typography>
      <Box>
        {Object.keys(commentCount).map((el) => {
          const perecentage = (commentCount[el] * 100) / total;
          return (
            <Typography key={el}>
              {el} - {perecentage.toFixed(2)}%
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default EventFeedback;
